import React from 'react';
import { Text, Link, Box, useColorMode } from '@chakra-ui/react';

import * as Consts from './Consts.js';

const parseText = (text) => {
    // Regular expression to match only http and https URLs
    const urlRegex = /(\bhttps?:\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
    return text.split(urlRegex);
};

const TextWithLinksAndNewLines = ({ text }) => {
    const { colorMode } = useColorMode();

    // Split the text by new lines
    const lines = text.split('\n');

    return (
        <Box border="1px" borderColor={Consts.fgColor[colorMode]} p={4} borderRadius="md">
            {lines.map((line, lineIndex) => (
                <Text key={lineIndex}>
                    {parseText(line).map((part, partIndex) =>
                        part.match(/^https?:\/\//) ? (
                            <Link key={partIndex} href={part} color="blue.500" isExternal>
                                {part}
                            </Link>
                        ) : (
                            <Text as="span" key={partIndex}>
                                {part}
                            </Text>
                        )
                    )}
                    {lineIndex !== lines.length - 1 && <br />}
                </Text>
            ))}
        </Box>
    );
};

export default TextWithLinksAndNewLines;
