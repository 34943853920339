import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import ListTitle from './ListTitle';
import CreateItem from './CreateItem';
import ItemLine from './ItemLine';
import {
    Spacer,
    HStack,
    useToast,
    Center,
    Spinner,
    Text,
    VStack,
    List,
    ListItem,
    Link,
    Button,
    useDisclosure,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Checkbox,
    Select,
    Editable,
    EditablePreview,
    EditableTextarea,
    useColorMode,
} from '@chakra-ui/react';

import * as Consts from './Consts.js';
import TextWithLinks from './TextWithLinks';

function Poll() {
    const { id } = useParams();
    const [items, setItems] = useState([]);
    const [listName, setListName] = useState('');
    const [listSubtitle, setListSubtitle] = useState('');
    const [oldListSubtitle, setOldListSubtitle] = useState('');
    const [isOwner, setIsOwner] = useState(false);
    const [everVoted, setEverVoted] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isEmpty, setIsEmpty] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [hoveredVoter, setHoveredVoter] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [sortingOption, setSortingOption] = useState('byVotes');
    const [isEditing, setIsEditing] = useState(false);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = React.useRef();

    const toast = useToast();

    const navigate = useNavigate();

    const { colorMode } = useColorMode();

    const fetchList = async (cont) => {
        Consts.fetchHandle(
            `https://api.${process.env.REACT_APP_DOMAIN}/lists/${id}/items`,
            {
                credentials: 'include',
            },
            (data) => {
                setItems(data.items);
                setListName(data.listName);
                setListSubtitle(data.subtitle);
                setOldListSubtitle(data.subtitle);
                setIsOwner(data.isOwner);
                setEverVoted(data.everVoted);

                if (data.items.length === 0) {
                    setIsEmpty(true);
                }
                setLoading(false);
                if (cont) {
                    cont();
                }

                document.title = data.listName + ' - ' + Consts.WebsiteName;
            },
            (error) => {
                toast({
                    title: error,
                    status: 'error',
                    isClosable: true,
                });

                setLoading(false);
                if (cont) {
                    cont();
                }
            }
        );
    };

    useEffect(() => {
        fetchList(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const downloadJson = () => {
        function sanitizeFilename(filename) {
            // return filename
            //     .replace(/[<>:"/\\|?*]+/g, '_')
            //     .replace(/[\\x00-\\x1F\\x80-\\x9F]+/g, '_')
            //     .replace(/[. ]+$/, '');
            return filename.replace(/[^a-z0-9]/gi, '_');
        }

        const sanitizedData = items.map(({ itemHash, voted, ...rest }) => rest);

        const fileName = sanitizeFilename(listName) + '.json';
        const json = JSON.stringify(sanitizedData, null, 4);
        const blob = new Blob([json], { type: 'application/json' });
        const href = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = href;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        URL.revokeObjectURL(href);
    };

    const handleDeleteList = async () => {
        setIsDeleting(true);
        onClose();
        Consts.fetchHandle(
            `https://api.${process.env.REACT_APP_DOMAIN}/lists/${id}`,
            {
                method: 'DELETE',
                credentials: 'include',
            },
            (data) => {
                navigate(`/`);
            },
            (error) => {
                toast({
                    title: error,
                    status: 'error',
                    isClosable: true,
                });
                setIsDeleting(false);
            }
        );
    };

    const handleUpdateSubtitle = async () => {
        if (listSubtitle === oldListSubtitle) {
            return;
        }

        Consts.fetchHandle(
            `https://api.${process.env.REACT_APP_DOMAIN}/lists/${id}`,
            {
                method: 'PUT',
                credentials: 'include',
                body: JSON.stringify({
                    subtitle: listSubtitle,
                }),
            },
            (data) => {
                fetchList();
                setOldListSubtitle(listSubtitle);
                toast({
                    title: 'Description updated',
                    status: 'success',
                    isClosable: true,
                });
            },
            (error) => {
                toast({
                    title: error,
                    status: 'error',
                    isClosable: true,
                });
                setListSubtitle(oldListSubtitle);
            }
        );
    };

    function extractAlphaSubstring(item) {
        var displayText = Consts.GetDisplayText(item.itemName, item.url, item.ogTitle);
        const match = displayText.match(/[0-9a-zA-Z]/);
        if (match) {
            return displayText.substring(match.index).toLowerCase();
        }
        return '\uFFFF' + displayText.toLowerCase();
    }

    return (
        <VStack spacing={4}>
            <ListTitle listHash={id} listName={listName} isOwner={isOwner} onRefresh={fetchList} />
            {!loading && (
                <Link href={'/'} textDecoration="none" fontSize="sm" _hover={{ color: 'blue.500' }}>
                    [Go Back]
                </Link>
            )}
            {!loading && (
                <HStack spacing={4}>
                    <Checkbox size="sm" isChecked={isChecked} onChange={(e) => setIsChecked(e.target.checked)}>
                        Show Voters
                    </Checkbox>
                    <Select size="sm" value={sortingOption} onChange={(e) => setSortingOption(e.target.value)}>
                        <option value="byVotes">Sort: Votes</option>
                        <option value="byName">Sort: Name</option>
                        <option value="byDate">Sort: Newest First</option>
                    </Select>
                </HStack>
            )}
            {isOwner ? (
                <Editable
                    border="1px"
                    borderColor={Consts.fgColor[colorMode]}
                    p={4}
                    borderRadius="md"
                    value={listSubtitle || (isEditing ? '' : 'Click here to add description')}
                    onChange={setListSubtitle}
                    onEdit={() => setIsEditing(true)}
                    onSubmit={() => {
                        setIsEditing(false);
                        handleUpdateSubtitle();
                    }}
                    onCancel={() => setIsEditing(false)}
                    color={listSubtitle ? undefined : Consts.fgColor[colorMode]}
                >
                    <EditablePreview style={{ whiteSpace: 'pre-wrap' }} />
                    <EditableTextarea />
                </Editable>
            ) : listSubtitle ? (
                <TextWithLinks text={listSubtitle} />
            ) : (
                <></>
            )}
            <Center>
                <List spacing={4}>
                    {(sortingOption === 'byVotes'
                        ? items.slice().sort((a, b) => b.votes - a.votes)
                        : sortingOption === 'byName'
                          ? items.slice().sort((a, b) => {
                                const alphaSubA = extractAlphaSubstring(a);
                                const alphaSubB = extractAlphaSubstring(b);
                                return alphaSubA.localeCompare(alphaSubB);
                            })
                          : items
                    ).map((it) => (
                        <ListItem key={it.itemHash}>
                            <ItemLine
                                listHash={id}
                                theItem={it}
                                isListOwner={isOwner}
                                everVoted={everVoted}
                                onRefresh={fetchList}
                                hoveredVoter={hoveredVoter}
                                setHoveredVoter={setHoveredVoter}
                                isChecked={isChecked}
                            />
                        </ListItem>
                    ))}
                </List>
            </Center>
            {isEmpty && (isOwner ? <Text>Poll is empty. Add an item below.</Text> : <Text>Poll is empty.</Text>)}
            {loading && <Spinner />}
            <Center>{isOwner && <CreateItem onRefresh={fetchList} listHash={id} />}</Center>
            <Spacer />
            <Spacer />
            <Center>
                {isOwner && (
                    <HStack spacing={4}>
                        <Button size="sm" onClick={downloadJson}>
                            Download
                        </Button>
                        <>
                            <Button
                                size="sm"
                                colorScheme="red"
                                onClick={onOpen}
                                isLoading={isDeleting}
                                loadingText=""
                                spinnerPlacement="end"
                            >
                                Delete
                            </Button>

                            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
                                <AlertDialogOverlay>
                                    <AlertDialogContent>
                                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                            Delete Poll
                                        </AlertDialogHeader>

                                        <AlertDialogBody>{'Delete poll "' + listName + '"?'}</AlertDialogBody>

                                        <AlertDialogFooter>
                                            <Button ref={cancelRef} onClick={onClose}>
                                                Cancel
                                            </Button>
                                            <Button colorScheme="red" onClick={handleDeleteList} ml={3}>
                                                Delete
                                            </Button>
                                        </AlertDialogFooter>
                                    </AlertDialogContent>
                                </AlertDialogOverlay>
                            </AlertDialog>
                        </>
                    </HStack>
                )}
            </Center>
            <Spacer />
        </VStack>
    );
}

export default Poll;
