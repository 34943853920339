import React, { useState, useEffect } from 'react';
import { Box, Editable, EditableInput, EditablePreview, useToast } from '@chakra-ui/react';

import * as Consts from './Consts.js';

function ListTitle({ listHash, listName, isOwner, onRefresh }) {
    const [title, setTitle] = useState(listName);
    const [oldTitle, setOldTitle] = useState(listName);

    const handleTitleChange = (newValue) => {
        setTitle(newValue);
    };

    const toast = useToast();

    useEffect(() => {
        setTitle(listName);
        setOldTitle(listName);
    }, [listName]);

    const handleModifyList = async () => {
        if (title === oldTitle) {
            return;
        }

        Consts.fetchHandle(
            `https://api.${process.env.REACT_APP_DOMAIN}/lists/${listHash}`,
            {
                method: 'PUT',
                credentials: 'include',
                body: JSON.stringify({
                    listName: title,
                }),
            },
            (data) => {
                onRefresh();
                setOldTitle(title);
                toast({
                    title: 'Title updated',
                    status: 'success',
                    isClosable: true,
                });
            },
            (error) => {
                toast({
                    title: error,
                    status: 'error',
                    isClosable: true,
                });
                setTitle(oldTitle);
            }
        );
    };

    return (
        <Box>
            <Editable
                fontSize="4xl"
                fontWeight="bold"
                fontFamily="Noto Serif, Georgia, Times, serif"
                value={title}
                isDisabled={!isOwner}
                onChange={handleTitleChange}
                onSubmit={handleModifyList}
            >
                <EditablePreview />
                <EditableInput />
            </Editable>
        </Box>
    );
}

export default ListTitle;
