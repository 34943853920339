import React, { useEffect } from 'react';
import { HStack, Text, useClipboard, Button } from '@chakra-ui/react';
import Cookies from 'js-cookie';

function Account({ everVoted }) {
    // eslint-disable-next-line no-unused-vars
    const { onCopy, _value, setValue, hasCopied } = useClipboard('');

    useEffect(() => {
        var userId = Cookies.get('userId');
        setValue(`https://${process.env.REACT_APP_DOMAIN}/user/${userId}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {everVoted && (
                <HStack>
                    <Text fontSize="xs">Move to another browser?</Text>
                    <Button size="xs" onClick={onCopy}>
                        {hasCopied ? 'Copied!' : 'Copy Link'}
                    </Button>
                </HStack>
            )}
        </>
    );
}

export default Account;
