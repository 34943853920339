import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useColorModeValue, Box, Link } from '@chakra-ui/react';

import Main from './Main.js';
import Poll from './Poll.js';
import User from './User.js';
import Navbar from './Navbar.js';
import * as Consts from './Consts.js';
import './App.css';

function App() {
    const bg = useColorModeValue(Consts.bgColor.light, Consts.bgColor.dark);

    const email = 'hello@' + Consts.WebsiteName;

    return (
        <Box width="100%" bg={bg} fontFamily="Noto Sans, Verdana, sans-serif">
            <Navbar />
            <Box width="80%" mx="auto">
                <Router>
                    <Routes>
                        <Route exact path="/" element={<Main />} />
                        <Route path="/poll/:id" element={<Poll />} />
                        <Route path="/list/:id" element={<Poll />} />
                        <Route path="/user/:id" element={<User />} />
                    </Routes>
                </Router>
            </Box>
            <Box align="center" w="100%" px={5} py={4}>
                <Link fontSize="sm" href={'mailto:' + email} isExternal>
                    {email}
                </Link>
            </Box>
        </Box>
    );
}

export default App;
