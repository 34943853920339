import React, { useState } from 'react';
import { useToast, Button, Input, VStack, HStack, Textarea, FormControl } from '@chakra-ui/react';

import * as Consts from './Consts.js';

function CreateItem({ onRefresh, listHash }) {
    const [textBoxValue, setTextBoxValue] = useState('');
    const [multilineValue, setMultilineValue] = useState('');
    const [isAdding, setIsAdding] = useState(false);
    const [isBulkAdding, setIsBulkAdding] = useState(false);
    const toast = useToast();

    const handleCreateItem = async (event) => {
        event.preventDefault();

        setIsAdding(true);

        Consts.fetchHandle(
            `https://api.${process.env.REACT_APP_DOMAIN}/lists/${listHash}/items`,
            {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify({
                    itemNames: [textBoxValue],
                }),
            },
            (data) => {
                setTextBoxValue('');
                onRefresh(() => {
                    setIsAdding(false);
                    toast({
                        title: 'Item added',
                        status: 'success',
                        isClosable: true,
                    });
                });
            },
            (error) => {
                toast({
                    title: error,
                    status: 'error',
                    isClosable: true,
                });
                setIsAdding(false);
            }
        );
    };

    const handleCreateItems = async () => {
        setIsBulkAdding(true);

        const lines = multilineValue.split('\n').filter((line) => line.trim() !== '');

        Consts.fetchHandle(
            `https://api.${process.env.REACT_APP_DOMAIN}/lists/${listHash}/items`,
            {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify({
                    itemNames: lines,
                }),
            },
            (data) => {
                setMultilineValue('');
                onRefresh(() => {
                    setIsBulkAdding(false);
                    toast({
                        title: 'Items added',
                        status: 'success',
                        isClosable: true,
                    });
                });
            },
            (error) => {
                toast({
                    title: error,
                    status: 'error',
                    isClosable: true,
                });
                setIsBulkAdding(false);
            }
        );
    };

    return (
        <VStack>
            <form onSubmit={handleCreateItem}>
                <FormControl>
                    <HStack>
                        <Input
                            placeholder="New item"
                            value={textBoxValue}
                            onChange={(e) => setTextBoxValue(e.target.value)}
                        />
                        <Button
                            type="submit"
                            isDisabled={textBoxValue.trim() === ''}
                            isLoading={isAdding}
                            loadingText=""
                            spinnerPlacement="end"
                        >
                            Add
                        </Button>
                    </HStack>
                </FormControl>
            </form>
            <HStack>
                <Textarea
                    value={multilineValue}
                    onChange={(e) => setMultilineValue(e.target.value)}
                    placeholder="One item per line; empty lines ignored"
                    height="200px"
                />
                <Button
                    onClick={handleCreateItems}
                    isDisabled={multilineValue.trim() === ''}
                    isLoading={isBulkAdding}
                    loadingText=""
                    spinnerPlacement="end"
                >
                    Bulk Add
                </Button>
            </HStack>
        </VStack>
    );
}

export default CreateItem;
