export const bgColor = { light: '#d3dae3', dark: '#2d2d2d' };
export const fgColor = { light: '#2d2d2d', dark: '#d3dae3' };

export const WebsiteName = 'polls.games';

export const fetchHandle = (link, params, success, fail) => {
    fetch(link, params)
        .then((response) => {
            if (!response.ok) {
                return response.json().then((errorData) => {
                    let errMsg = 'Error';
                    if (errorData && errorData.errorMessage) {
                        errMsg += `: ${errorData.errorMessage}`;
                    }
                    throw new Error(errMsg);
                });
            }
            return response.json();
        })
        .then((data) => success(data))
        .catch((error) => {
            fail(error.message);
        });
};

export const GetDisplayText = (original, url, text) => {
    if (!url) {
        return original;
    }

    var split = original.split(url);
    return split.map((part, index) => (index === split.length - 1 ? part : part + '[' + text + ']')).join('');
};
