import React from 'react';
import { Flex, Link, useColorMode } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import * as Consts from './Consts.js';

const NavLink = ({ children, ...props }) => (
    <Link textAlign="right" mt={[4, 4, 0, 0, 0]} ml={6} display="block" onClick={props.onClick} href={props.href}>
        {children}
    </Link>
);

const Navbar = (props) => {
    const { colorMode, toggleColorMode } = useColorMode();

    return (
        <Flex
            as="nav"
            bg={Consts.bgColor[colorMode]}
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
            w="100%"
            px={5}
            py={4}
        >
            <Flex flexDirection="row" justifyContent="center" alignItems="center">
                <Link href="/" fontFamily="Noto Serif, Georgia, Times, serif" fontSize="4xl" fontWeight="bold">
                    {Consts.WebsiteName}
                </Link>
            </Flex>

            <NavLink onClick={toggleColorMode}>
                <FontAwesomeIcon icon={colorMode === 'light' ? faMoon : faSun} />
            </NavLink>
        </Flex>
    );
};

export default Navbar;
