import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useToast, Center, Spinner, Text, HStack, Link } from '@chakra-ui/react';

import * as Consts from './Consts.js';

function User() {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);

    const toast = useToast();

    const navigate = useNavigate();

    const fetchUser = async () => {
        setLoading(true);

        Consts.fetchHandle(
            `https://api.${process.env.REACT_APP_DOMAIN}/users/${id}`,
            {
                method: 'PUT',
                credentials: 'include',
            },
            (data) => {
                navigate(`/`);
            },
            (error) => {
                toast({
                    title: error,
                    status: 'error',
                    isClosable: true,
                });

                setLoading(false);
            }
        );
    };

    useEffect(() => {
        fetchUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Center>
            {loading ? (
                <HStack spacing={4}>
                    <Spinner />
                    <Text>Restoring User...</Text>
                </HStack>
            ) : (
                <Link href={'/'} textDecoration="none" _hover={{ color: 'blue.500' }}>
                    Back
                </Link>
            )}
        </Center>
    );
}

export default User;
