import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquarePollVertical, faUser } from '@fortawesome/free-solid-svg-icons';
import {
    Spacer,
    useToast,
    Center,
    Heading,
    Spinner,
    Text,
    VStack,
    List,
    ListItem,
    Link,
    HStack,
} from '@chakra-ui/react';

import CreateList from './CreateList';
import Account from './Account';
import * as Consts from './Consts.js';

function Main() {
    const [lists, setLists] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isEmpty, setIsEmpty] = useState(false);
    const [everVoted, setEverVoted] = useState(false);

    const toast = useToast();

    useEffect(() => {
        Consts.fetchHandle(
            `https://api.${process.env.REACT_APP_DOMAIN}/lists`,
            {
                credentials: 'include',
            },
            (data) => {
                setLists(data.lists);
                setLoading(false);
                if (data.lists.length === 0) {
                    setIsEmpty(true);
                }
                setEverVoted(data.everVoted);
            },
            (error) => {
                toast({
                    title: error,
                    status: 'error',
                    isClosable: true,
                });

                setLoading(false);
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Center>
                <VStack spacing={8}>
                    <Heading fontFamily="Noto Serif, Georgia, Times, serif" fontWeight="bold">
                        My Polls
                    </Heading>
                    <List spacing={1}>
                        {lists.map((list) => (
                            <ListItem key={list.listHash}>
                                <Link
                                    href={`/poll/${list.listHash}`}
                                    textDecoration="none"
                                    _hover={{ color: 'blue.500' }}
                                >
                                    <HStack spacing={2} align="center">
                                        <FontAwesomeIcon icon={faSquarePollVertical} />
                                        <Text fontSize="xl">{list.listName}</Text>
                                        {list.isOwner && <FontAwesomeIcon icon={faUser} />}
                                    </HStack>
                                </Link>
                            </ListItem>
                        ))}
                    </List>
                    {isEmpty && <Text>Create your first poll ↓</Text>}
                    {loading && <Spinner />}
                    <CreateList />
                    <Spacer />
                    <Account everVoted={everVoted} />
                </VStack>
            </Center>
        </>
    );
}

export default Main;
