import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { ChakraProvider, extendTheme, ColorModeScript } from '@chakra-ui/react';

const breakpoints = {
    base: '0px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1280px',
    '2xl': '1536px',
};

const styles = {
    global: (props) => ({
        'html, body': {
            backgroundColor: props.colorMode === 'dark' ? '#2d2d2d' : '#d3dae3',
        },
    }),
};

const customTheme = extendTheme({ breakpoints, styles });

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <React.StrictMode>
        <ChakraProvider theme={customTheme}>
            <ColorModeScript />
            <App />
        </ChakraProvider>
    </React.StrictMode>
);
