import React, { useState } from 'react';
import {
    useDisclosure,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Button,
    Editable,
    EditableInput,
    EditablePreview,
    useToast,
    Box,
    HStack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    Input,
    VStack,
    Tag,
    Divider,
    Text,
    Tooltip,
    Image,
    Link,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';

import * as Consts from './Consts.js';

const MAX_DISPLAYED_VOTERS = 10;

function ItemLine({ listHash, theItem, isListOwner, everVoted, onRefresh, hoveredVoter, setHoveredVoter, isChecked }) {
    const [title, setTitle] = useState(theItem.itemName);
    const [oldTitle, setOldTitle] = useState(theItem.itemName);
    const [isVoting, setIsVoting] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [person, setPerson] = useState('');

    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = React.useRef();

    const { isOpen: isNameOpen, onOpen: onNameOpen, onClose: onNameClose } = useDisclosure();
    const initialRef = React.useRef();

    const [isEditing, setIsEditing] = React.useState(false);

    const toast = useToast();

    const handleTitleChange = (newValue) => {
        setTitle(newValue);
    };

    const handleModifyItem = async () => {
        if (title === oldTitle) {
            return;
        }

        Consts.fetchHandle(
            `https://api.${process.env.REACT_APP_DOMAIN}/lists/${listHash}/items/${theItem.itemHash}`,
            {
                method: 'PUT',
                credentials: 'include',
                body: JSON.stringify({
                    itemName: title,
                }),
            },
            (data) => {
                onRefresh();
                setOldTitle(title);
                toast({
                    title: "Item's name updated",
                    status: 'success',
                    isClosable: true,
                });
            },
            (error) => {
                toast({
                    title: error,
                    status: 'error',
                    isClosable: true,
                });
                setTitle(oldTitle);
            }
        );
    };

    const handleDeleteItem = async () => {
        setIsDeleting(true);
        onClose();
        Consts.fetchHandle(
            `https://api.${process.env.REACT_APP_DOMAIN}/lists/${listHash}/items/${theItem.itemHash}`,
            {
                method: 'DELETE',
                credentials: 'include',
            },
            (data) => {
                onRefresh(() => {
                    setIsDeleting(false);
                    toast({
                        title: 'Item deleted',
                        status: 'info',
                        isClosable: true,
                    });
                });
            },
            (error) => {
                toast({
                    title: error,
                    status: 'error',
                    isClosable: true,
                });
                setIsDeleting(false);
            }
        );
    };

    const handleVote = async () => {
        setIsVoting(true);

        var method = theItem.voted ? 'DELETE' : 'POST';
        var args = {
            method: method,
            credentials: 'include',
        };

        if (!everVoted) {
            args.body = JSON.stringify({
                voterName: person,
            });
        }

        Consts.fetchHandle(
            `https://api.${process.env.REACT_APP_DOMAIN}/lists/${listHash}/items/${theItem.itemHash}/vote`,
            args,
            (data) => {
                onRefresh(() => {
                    toast({
                        title: theItem.voted ? 'Removed vote' : 'Vote submitted',
                        status: theItem.voted ? 'info' : 'success',
                        isClosable: true,
                    });

                    setIsVoting(false);
                });
            },
            (error) => {
                toast({
                    title: error,
                    status: 'error',
                    isClosable: true,
                });
                setIsVoting(false);
            }
        );
    };

    const handleSubmitClick = (event) => {
        event.preventDefault();

        onNameClose();
        handleVote();
    };

    const voteClick = () => {
        if (!everVoted) {
            onNameOpen();
        } else {
            handleVote();
        }
    };

    const voterHovered = (voter) => {
        if (voter === hoveredVoter) {
            setHoveredVoter('');
        } else {
            setHoveredVoter(voter);
        }
    };

    const displayText = Consts.GetDisplayText(title, theItem.url, theItem.ogTitle);

    const tooltippedContent = isListOwner ? (
        <Editable
            value={isEditing ? title : displayText}
            onChange={handleTitleChange}
            onSubmit={() => {
                setIsEditing(false);
                handleModifyItem();
            }}
            onEdit={() => setIsEditing(true)}
            onCancel={() => setIsEditing(false)}
            width="100%"
        >
            <EditablePreview wordBreak="break-all" />
            <EditableInput />
        </Editable>
    ) : (
        <Box>
            {title.split(theItem.url).map((part, index) =>
                index === title.split(theItem.url).length - 1 ? (
                    part
                ) : (
                    <React.Fragment key={index}>
                        {part}
                        <Link href={theItem.url} isExternal>
                            [{theItem.ogTitle}]
                        </Link>
                    </React.Fragment>
                )
            )}
        </Box>
    );

    return (
        <Box>
            <VStack align="stretch" spacing={2}>
                <HStack spacing={2}>
                    <>
                        <Button
                            onClick={voteClick}
                            size="sm"
                            {...(theItem.voted ? { colorScheme: 'green' } : {})}
                            isLoading={isVoting}
                            loadingText=""
                            spinnerPlacement="end"
                            width="10px"
                        >
                            {false ? <FontAwesomeIcon icon={theItem.voted ? faAngleDown : faAngleUp} /> : theItem.votes}
                        </Button>
                        <Modal initialFocusRef={initialRef} isOpen={isNameOpen} onClose={onNameClose}>
                            <ModalOverlay />
                            <ModalContent as="form" onSubmit={handleSubmitClick}>
                                <ModalHeader>Please Enter Your Name</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody pb={6}>
                                    <FormControl>
                                        <Input
                                            ref={initialRef}
                                            placeholder=""
                                            value={person}
                                            onChange={(e) => setPerson(e.target.value)}
                                        />
                                    </FormControl>
                                </ModalBody>

                                <ModalFooter>
                                    <Button colorScheme="blue" mr={3} type="submit" isDisabled={person.trim() === ''}>
                                        Submit
                                    </Button>
                                    <Button onClick={onNameClose}>Cancel</Button>
                                </ModalFooter>
                            </ModalContent>
                        </Modal>
                    </>
                    {theItem.ogImage ? (
                        <Tooltip
                            label={<Image src={theItem.ogImage} style={{ maxWidth: '200px', width: '100%' }} />}
                            placement="bottom-start"
                            hasArrow
                        >
                            {tooltippedContent}
                        </Tooltip>
                    ) : (
                        tooltippedContent
                    )}
                    {isListOwner && (
                        <>
                            <Button
                                colorScheme="red"
                                size="sm"
                                onClick={onOpen}
                                isLoading={isDeleting}
                                loadingText=""
                                spinnerPlacement="end"
                                width="10px"
                            >
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>

                            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
                                <AlertDialogOverlay>
                                    <AlertDialogContent>
                                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                            Delete Item
                                        </AlertDialogHeader>

                                        <AlertDialogBody>{'Delete item "' + theItem.itemName + '"?'}</AlertDialogBody>

                                        <AlertDialogFooter>
                                            <Button ref={cancelRef} onClick={onClose}>
                                                Cancel
                                            </Button>
                                            <Button colorScheme="red" onClick={handleDeleteItem} ml={3}>
                                                Delete
                                            </Button>
                                        </AlertDialogFooter>
                                    </AlertDialogContent>
                                </AlertDialogOverlay>
                            </AlertDialog>
                        </>
                    )}
                </HStack>
                {isChecked && (
                    <HStack mx={'40px'}>
                        {theItem.voters.slice(0, MAX_DISPLAYED_VOTERS).map((person) => (
                            <Tag
                                key={person}
                                cursor="pointer"
                                onClick={() => voterHovered(person)}
                                {...(person === hoveredVoter ? { colorScheme: 'green' } : {})}
                            >
                                {person}
                            </Tag>
                        ))}
                        {theItem.voters.length > MAX_DISPLAYED_VOTERS && <Text>...</Text>}
                    </HStack>
                )}
                {isChecked && <Divider my={2} />}
            </VStack>
        </Box>
    );
}

export default ItemLine;
