import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast, Button, Input, HStack, FormControl } from '@chakra-ui/react';

import * as Consts from './Consts.js';

function CreateList() {
    const placeholder = 'New Poll';
    const [textBoxValue, setTextBoxValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const toast = useToast();

    const handleCreateList = async (event) => {
        event.preventDefault();

        setIsLoading(true);

        Consts.fetchHandle(
            `https://api.${process.env.REACT_APP_DOMAIN}/lists`,
            {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify({
                    listName: textBoxValue,
                }),
            },
            (data) => {
                navigate(`/poll/${data.listHash}`);
            },
            (error) => {
                toast({
                    title: error,
                    status: 'error',
                    isClosable: true,
                });

                setIsLoading(false);
            }
        );
    };

    return (
        <form onSubmit={handleCreateList}>
            <FormControl>
                <HStack>
                    <Input
                        placeholder={placeholder}
                        value={textBoxValue}
                        onChange={(e) => setTextBoxValue(e.target.value)}
                    />
                    <Button
                        type="submit"
                        isDisabled={textBoxValue.trim() === ''}
                        isLoading={isLoading}
                        loadingText=""
                        spinnerPlacement="end"
                    >
                        Create
                    </Button>
                </HStack>
            </FormControl>
        </form>
    );
}

export default CreateList;
